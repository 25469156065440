@import "./varuables.scss";
* {
	padding: 0;
	margin: 0;
	color: inherit;
	text-decoration: none;
	list-style: none;
	box-sizing: border-box;
	transition: 0.3s;
	font-family: $font_titles;
}
a:hover {
	color: $reddish;
}
::-webkit-scrollbar {
	color: $denger;
	background-color: rgba(255, 255, 255, 0.147);
	border: 1px solid rgb(255, 255, 255);
	width: 8px;
	height: 0px;
	cursor: pointer;
}
::-webkit-scrollbar-thumb {
	color: $light;
	background-color: $reddish;
	border-radius: 50%;
	cursor: pointer;
}
::-webkit-scrollbar-button {
	display: none;
}
body {
	overflow-x: hidden;
	margin: 0 auto;
}
.wrapper {
	height: auto;
	width: $w_90;
	margin: 0 auto;
}
