
@import "../../scss/main.scss";
* {
	padding: 0;
	margin: 0;
}
.slider {
	margin: 0;
	position: relative;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.image {
		width: 95vw;
		height: 95vh;
		border-radius: 0;
		object-fit: cover;
	}
	.slide-elements {
		width: 50%;
		position: absolute;
		top: 40%;
		left: 25%;
		z-index: 1234;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		text-align: center;

		.title {
			text-align: start;
			text-transform: uppercase;
			color: $light;
			font-size: 2rem;
			text-align: center;
			text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.545);
		}
		.description {
			font-size: 1.8rem;
			font-family: sans-serif;
			color: #fff;
			text-align: center;
			text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.545);
		}
		a {
			width: 35%;
			button {
				width: 100%;
				overflow: hidden;
			}
		}
	}
}

.right-arrow {
	position: absolute;
	top: 50%;
	right: 32px;
	font-size: 3rem;
	color: rgba(0, 0, 0, 0.772);
	z-index: 1;
	cursor: pointer;
	user-select: none;
	opacity: 0.6;
}

.left-arrow {
	position: absolute;
	top: 50%;
	left: 32px;
	font-size: 3rem;
	color: rgba(0, 0, 0, 0.772);
	z-index: 1;
	cursor: pointer;
	user-select: none;
	opacity: 0.6;
}
.left-arrow-div {
	width: 50%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}
.right-arrow-div {
	width: 50%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
}
.slide {
	opacity: 0.8;
	transition-duration: 2s ease;
}
.slide.active {
	opacity: 1;
	transition-duration: 2s;
	transform: scale(1.08);
}

@media (min-width: $xxl) {
	.slider {
		.slide-elements {
			.description {
				font-size: 3rem;
			}
			.title {
				font-size: 4.5rem;
			}
			a {
				width: 30%;
				button {
					width: 100%;
					font-size: 1.8rem;
				}
			}
		}
	}

	.right-arrow {
		font-size: 3.5rem;
	}

	.left-arrow {
		font-size: 3.5rem;
	}
}

@media (max-width: $xxl) {
	.slider {
		.slide-elements {
			gap: 2rem;
			.description {
				font-size: 1.5rem;
				font-family: $font_texts;
				color: $light;
			}
			.title {
				font-size: 3rem;
			}
			a {
				width: auto;
				min-width: 33%;
				button {
					width: 100%;
				}
			}
		}
	}

	.right-arrow {
		right: 20px;
		font-size: 2.5rem;
	}

	.left-arrow {
		left: 20px;
		font-size: 2.5rem;
	}
}

@media (max-width: $xl) {
	.slider {
		.slide-elements {
			.description {
				font-size: 1.2rem;
				font-family: $font_texts;
				color: $light;
			}
			.title {
				font-size: 2.3rem;
			}
			a {
				width: 50%;
				button {
					width: 100%;
				}
			}
		}
	}

	.right-arrow {
		right: 20px;
		font-size: 2.5rem;
	}

	.left-arrow {
		left: 20px;
		font-size: 2.5rem;
	}
}

@media (max-width: $lg) {
	.slider {
		height: 90vh;
		width: 100%;
		.image {
			width: 95vw;
			height: 100vh;
		}
		.slide-elements {
			width: 60%;
			top: 35%;
			left: 20%;
			gap: 2rem;
			.description {
				font-size: 1.2rem;
			}
			.title {
				font-size: 2.5rem;
			}
			a {
				width: 45%;
				button {
					width: 100%;
				}
			}
		}
	}

	.right-arrow {
		right: 20px;
		font-size: 2.5rem;
	}

	.left-arrow {
		left: 20px;
		font-size: 2.5rem;
	}
}

@media (max-width: $md) {
	.slider {
		height: 90vh;
		.image {
			width: 95vw;
			height: 90vh;
		}
		.slide-elements {
			width: 70%;
			left: 15%;
			gap: 1.5rem;
			.description {
				font-size: 1rem;
			}
			.title {
				font-size: 2.2rem;
			}
			a {
				width: 40%;
				button {
					width: 100%;
					font-size: 0.9rem;
				}
			}
		}
	}

	.right-arrow {
		right: 18px;
		font-size: 2.1rem;
	}

	.left-arrow {
		left: 18px;
		font-size: 2.1rem;
	}
}

@media (max-width: $sm) {
	.slider {
		height: 90vh;
		.image {
			height: 90vh;
		}
		.slide-elements {
			width: 70%;
			left: 15%;
			gap: 1.3rem;
			top: 40%;
			.description {
				font-size: 0.8rem;
			}
			.title {
				font-size: 1.7rem;
			}
			a {
				width: 50%;
				button {
					width: 100%;
					font-size: 0.8rem;
				}
			}
		}
	}

	.right-arrow {
		right: 10px;
		font-size: 1.8rem;
	}

	.left-arrow {
		left: 10px;
		font-size: 1.8rem;
	}
}

@media (max-width: $xs) {
	.slider {
		height: 90vh;
		.image {
			width: 95vw;
			height: 90vh;
		}
		.slide-elements {
			width: 80%;
			left: 10%;
			gap: 1rem;
			.description {
				font-size: 0.6rem;
			}
			.title {
				font-size: 1.2rem;
			}
			a {
				width: 50%;
				button {
					width: 100%;
					font-size: 0.7rem;
				}
			}
		}
	}

	.right-arrow {
		right: 7px;
		font-size: 1.2rem;
	}

	.left-arrow {
		left: 7px;
		font-size: 1.2rem;
	}
}
