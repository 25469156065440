@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.cards {
	padding: 10% 0;
	display: flex;
	justify-content: space-between;
	align-items: start;
	flex-wrap: wrap;
	.card {
		width: 24%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1em;
		text-align: justify;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.378);
		border-radius: $br_r_5;
		overflow: hidden;
		padding: 0 0 2% 0;
		&:hover {
			box-shadow: 0 7px 20px rgba(0, 0, 0, 0.555);
			transform: scale(1.02);
		}
		img {
			width: 100%;
			height: 240px;
			object-fit: cover;
			border-radius: 5px 5px 0px 0px;
			&:hover {
				transform: scale(1.02);
			}
		}
		h3 {
			font-family: $font_titles;
		}
		p {
			width: 80%;
			font-family: $font_texts;
			line-height: 1.5rem;
			color: #666;
			text-align: justify;
		}
		.link {
			display: block;
			// border: 2px solid blue;
			// width: 100%;
			button {
				// border: 2px solid blue;

				padding: 0% 1%;
				width: 100%;
			}
		}
	}
}

@media (max-width: $xl) {
	.cards {
		padding: 10% 0;
		row-gap: 2.5rem;
		.card {
			width: 48%;
			img {
				height: 240px;
			}

			p {
				line-height: 1.5rem;
				text-align: justify;
			}
			button {
				padding: 0% 8%;
				font-size: 1.1rem;
			}
		}
	}
}

@media (max-width: $lg) {
	.cards {
		padding: 10% 0;
		row-gap: 2rem;
		.card {
			width: 48%;
			img {
				height: 220px;
			}

			p {
				line-height: 1.4rem;
				width: 85%;
			}
			button {
				padding: 0% 8%;
				font-size: 1rem;
			}
		}
	}
}

@media (max-width: $md) {
	.cards {
		padding: 10% 0;
		row-gap: 2.5rem;
		justify-content: center;
		.card {
			width: 75%;
			padding: 0 0 5% 0;
			img {
				height: 230px;
			}

			p {
				line-height: 1.3rem;
				width: 80%;
			}
			button {
				padding: 0% 8%;
				font-size: 1rem;
			}
		}
	}
}

@media (max-width: $sm) {
	.cards {
		row-gap: 2.5rem;
		.card {
			width: 100%;
			padding: 0 0 5% 0;
			img {
				height: 210px;
			}

			p {
				line-height: 1.3rem;
				width: 90%;
				font-size: 0.8rem;
			}
			button {
				padding: 0% 7%;
				font-size: 0.9rem;
			}
		}
	}
}

@media (max-width: $xs) {
	.cards {
		row-gap: 2rem;
		.card {
			gap: 0.8em;
			img {
				height: 180px;
			}

			p {
				line-height: 1.1rem;
				width: 95%;
				font-size: 0.8rem;
			}
			button {
				padding: 0% 6%;
				font-size: 0.8rem;
			}
		}
	}
}
