@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.services {
	background: #fff;
	margin-top: 40vh;
	.nav {
		&__elements {
			&-linksDiv {
				&-ul {
					&-services {
						color: $denger;
						border-bottom: 2px solid $denger;
					}
				}
			}
		}
	}
	.page__title {
		position: absolute;
		left: 37%;
		top: 36vh;
		overflow: hidden;
		padding: 1% 0 2% 0;
		font-family: $font_titles;
		font-size: 3em;
		width: 26%;
		text-align: center;
		color: $dark;
		font-weight: 500;
		background: $light;
		text-shadow: 0 2px 3px rgba(0, 0, 0, 0.698);
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
		z-index: 12;
		border-radius: $br_r_5;
		text-transform: capitalize;
	}
}

@media (max-width: $xl) {
	.services{
		.page__title {
			left: 35%;
			top: 32vh;
			padding: 1% 0 2% 0;
			font-size: 2.9em;
			width: 30%;
		}
	}
}

@media (max-width: $lg) {
	.services{
		.page__title {
			left: 31%;
			top: 34vh;
			padding: 0% 0 1% 0;
			font-size: 2.6em;
			width: 38%;
		}
	}
}

@media (max-width: $md) {
	.services {
		.page__title {
			left: 28%;
			top: 35vh;
			font-size: 2.4em;
			width: 44%;
		}
		
	}
}

@media (max-width: $sm) {
	.services {
		.page__title {
			left: 26%;
			top: 36vh;
			font-size: 1.8em;
			width: 48%;
		}
		
	}
}

@media (max-width: $xs) {
	.services {
		.page__title {
			left: 20%;
			top: 37vh;
			font-size: 1.3em;
			width: 60%;
			padding: 0 1%;
		}
		
	}
}