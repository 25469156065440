@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
	color: inherit;
}
.main {
	width: 100%;
	overflow: hidden;
	input[type="checkbox"] {
		display: none;
	}
	input[type="checkbox"]:checked ~ .navLinks__menu {
		right: 0;
	}
	input[type="checkbox"]:checked + .nav__links {
		left: 0;
		border-right: 1px solid #de162a;
	}
	.navLinks__menu {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		right: -100%;
		z-index: 123;
		background: rgba(0, 0, 0, 0.749);
		transition: 0.5s;
	}
	.slider_div {
		background: $light;
		width: 100%;
	}

	.infoDiv {
		background: $light;
		padding: 5% 0 10% 0;
	}
}

.stages-elements-bg {
	width: $w_100;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	background-image: linear-gradient(rgba(0, 0, 0, 0.797), rgba(0, 0, 0, 0.797)),
		url("../../media/infoBg.jpg");
	background-size: cover;
}
.stages-elements {
	width: $w_100;
	border-top: 2px solid;
	border-bottom: 2px solid;
	padding: 6% 0;
	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		overflow: hidden;
		.stages-elements-col1 {
			width: 48%;
		}
		.stages-elements-col2 {
			width: 45%;
		}
	}
}
.partners-location {
	background: $light;
}
.home {
	.nav {
		&__elements {
			&-linksDiv {
				&-ul {
					&-home {
						color: $denger;
						border-bottom: 2px solid $denger;
					}
				}
			}
		}
	}
}
.success{
position: relative;
z-index: 99999;
}
@media (max-width: $xl) {
	.stages-elements {
		padding: 10% 0;
		.wrapper {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 50px;
			.stages-elements-col1 {
				width: 80%;
			}
			.stages-elements-col2 {
				width: 80%;
			}
		}
	}
}

@media (max-width: $lg) {
	.stages-elements {
		padding: 14% 0 18% 0;
		.wrapper {
			.stages-elements-col1 {
				width: 100%;
			}
			.stages-elements-col2 {
				width: 100%;
			}
		}
	}
}
