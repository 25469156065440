// responsive widths
$xs: 375px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1201px;
$xxl: 1920px;
// font-family
$font_titles: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
$font_texts: sans-serif;
$font_emoji: emoji;
$font_btn: monospace;
// colors
$dark: #000;
$light: #fff;
$reddish: #e54555;
$denger: #ff0000;
$grey: #666;
// border-radius
$br_r_10: 10px;
$br_r_5: 5px;
$br_r_0: 0;
// width-elements
$w_100: 100%;
$w_90: 90%;
$w_50: 50%;
