@import "../../scss/varuables.scss";
footer {
	width: $w_100;
	height: 150px;
	padding: 0 10%;
	background: #25292b;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $light;
	font-size: 1.3rem;
	.footer__logo {
		width: 16%;
		object-fit: cover;
		cursor: pointer;
	}
	.nav__elements-callsDiv-calls {
		width: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		&-numbers {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
	}
	ul {
		display: flex;
		justify-content: space-between;
		gap: 0.5em;
		li {
			a {
				font-size: 2rem;
			}
		}
	}
	.nav__otherElements {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
		&__language {
			align-self: flex-end;
			display: flex;
			gap: 0.5rem;
			&-img {
				width: 27px;
				height: 17px;
				object-fit: cover;
				box-shadow: 0 4px 6px rgba(0, 0, 0, 0.503);
				cursor: pointer;
				border: none;
			}
			.eng {
				background-image: url("../../media/UK-Union-Flag.png");
				background-size: cover;
			}
			.rus {
				background-image: url("../../media/russianFlag.png");
				background-size: cover;
			}
		}
	}
	.fa-solid {
		margin-right: 0.5rem;
	}
}
@media (min-width: $xxl) {
	footer {
		.footer__logo {
			width: 16%;
		}
		.nav__elements-callsDiv-calls {
			&-numbers {
			}
		}
		ul {
			gap: 1em;
			li {
				a {
					font-size: 3rem;
				}
			}
		}
		.nav__otherElements {
			&-mail {
				font-size: 1.8em;
			}
			&__language {
				&-img {
					width: 32px;
					height: 20px;
				}
			}
		}
	}
}
@media (max-width: $xxl) {
	footer {
		.footer__logo {
			width: 16%;
		}
		.nav__elements-callsDiv-calls {
			&-numbers {
				font-size: 1.2em;
			}
		}
		.nav__otherElements {
			&-mail {
				font-size: 1.2em;
			}
			&__language {
				&-img {
					width: 27px;
					height: 17px;
				}
			}
		}
	}
}
@media (max-width: $xl) {
	footer {
		.footer__logo {
			width: 17%;
		}
		.nav__elements-callsDiv-calls {
			&-numbers {
				font-size: 1em;
			}
		}
		ul {
			gap: 0.4em;
			li {
				a {
					font-size: 1.5rem;
				}
			}
		}
		.nav__otherElements {
			&-mail {
				font-size: 1em;
			}
			&__language {
				&-img {
					width: 22px;
					height: 14px;
				}
			}
		}
	}
}
@media (max-width: $lg) {
	footer {
		.footer__logo {
			width: 18%;
		}
		.nav__elements-callsDiv-calls {
			&-numbers {
				font-size: 1em;
			}
		}
		ul {
			gap: 0.3em;
			li {
				a {
					font-size: 1.2rem;
				}
			}
		}
		.nav__otherElements {
			&-mail {
				font-size: 0.8em;
			}
			&__language {
				&-img {
					width: 20px;
					height: 13px;
				}
			}
		}
	}
}
@media (max-width: $md) {
	footer {
		.footer__logo {
			width: 15%;
		}
		.nav__elements-callsDiv-calls {
			&-numbers {
				font-size: 1em;
			}
		}
		ul {
			gap: 0.2em;
			li {
				a {
					font-size: 1rem;
				}
			}
		}
		.nav__otherElements {
			&-mail {
				font-size: 0.7em;
				.fa-solid {
					margin-right: 0;
				}
			}
			&__language {
				&-img {
					width: 18px;
					height: 12px;
				}
			}
		}
	}
}
@media (max-width: $sm) {
	footer {
		padding: 4% 10%;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5em;
		.footer__logo {
			width: 30%;
		}
		.nav__elements-callsDiv-calls {
			&-numbers {
				font-size: 0.9em;
			}
		}
		ul {
			gap: 0.3em;
			li {
				a {
					font-size: 1rem;
				}
			}
		}
		.nav__otherElements {
			&-mail {
				font-size: 0.7em;
			}
			&__language {
				align-self: flex-start;
				&-img {
					width: 17px;
					height: 12px;
				}
			}
		}
	}
}
@media (max-width: $xs) {
	footer {
		overflow: hidden;
		.footer__logo {
			width: 50%;
		}
	}
}
