@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.showBg {
	width: 100%;
	height: 40vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -12;
	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
