@import "../../scss/varuables.scss";

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.inside {
	margin-top: 40vh;
	background: #fff;
	width: $w_100;
	overflow: hidden;
	.nav {
		&__elements {
			&-linksDiv {
				&-ul {
					&-services {
						color: $denger;
						border-bottom: 2px solid $denger;
					}
				}
			}
		}
	}
	.page__title {
		width: 36%;
		position: absolute;
		left: 32%;
		top: 36vh;
		overflow: hidden;
		padding: 1% 0 2% 0;
		font-family: $font_titles;
		font-size: 2.5em;
		text-align: center;
		color: $dark;
		font-weight: 500;
		background: $light;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.298);
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
		z-index: 12;
		border-radius: $br_r_5;
		text-transform: capitalize;

		span {
			font-size: 1.2rem;
		}
	}

	.inside__elements {
		padding: 8% 5% 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		&-col1 {
			// border: 2px solid red;
			width: 27%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1rem;
			h3 {
				padding: 1% 3%;
				color: $reddish;
				border-bottom: 3px solid $reddish;
				text-align: center;
				margin: 0% auto 4% auto;
			}

			&-categories {
				// border: 2px solid green;
				width: $w_100;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 0.7rem;
				cursor: pointer;
				border-bottom: 1px solid #ddd;
				padding-bottom: 1rem;

				img {
					height: 100px;
					width: 150px;
					object-fit: cover;
					border-radius: $br_r_5;
				}
			}
		}

		&-col2 {
			width: 65%;
			&-elements {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 1rem;

				img {
					width: 100%;
					height: 400px;
					object-fit: cover;
					border-radius: $br_r_5;
				}

				p {
					font-family: $font_texts;
					color: #666;
					line-height: 1.5rem;
					text-align: justify;
				}
			}
		}
	}
}
@media (max-width: $xl) {
	.inside {
		.page__title {
			width: 44%;
			left: 28%;
			top: 33vh;
			padding: 1% 0 2% 0;
			font-size: 2.8em;
			font-weight: 500;
			text-shadow: 0 2px 3px rgba(0, 0, 0, 0.698);
			box-shadow: 0 4px 15px rgba(0, 0, 0, 0.647);
			span {
				font-size: 1.4rem;
			}
		}
		.inside__elements {
			padding: 7% 4%;
		}
	}
}

@media (max-width: $lg) {
	.inside {
		.page__title {
			width: 50%;
			left: 25%;
			top: 34vh;
			padding: 1% 0 2% 0;
			font-size: 2.4em;
			font-weight: 500;
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.698);
			box-shadow: 0 4px 15px rgba(0, 0, 0, 0.647);
			span {
				font-size: 1.3rem;
			}
		}
		.inside__elements {
			padding: 10% 0 5% 0;
			&-col1 {
				width: 34%;
				gap: 0.8rem;
				&-categories {
					gap: 0.3rem;
					padding-bottom: 0.8rem;
					img {
						height: 90px;
						width: 130px;
					}
				}
			}
			&-col2 {
				width: 65%;
				&-elements {
					gap: 0.5rem;
					img {
						width: 100%;
						height: 350px;
					}
					p {
						line-height: 1.3rem;
						text-align: justify;
					}
				}
			}
		}
	}
}

@media (max-width: $md) {
	.inside {
		.link {
			display: block;
			width: 40%;
			overflow: hidden;
			text-align: center;
		}
		.page__title {
			width: 60%;
			left: 20%;
			top: 35vh;
			padding: 1% 0 2% 0;
			font-size: 2.2em;
			font-weight: 500;
			span {
				font-size: 1.1rem;
			}
		}
		.inside__elements {
			margin-top: 20%;
			padding: 0 0 5% 0;
			position: relative;
			&-col1 {
				background: rgba(0, 0, 0, 0.558);
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: $w_100;
				height: 350px;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				gap: 0rem;
				border-radius: $br_r_5;
				h3 {
					width: 50%;
					position: absolute;
					top: -18%;
					left: 25%;
				}
				&-categories {
					border: 3px solid $light;
					width: 100%;
					height: 120px;
					gap: 0.0001rem;
					padding-bottom: 0rem;
					padding: 3%;
					color: $light;
					text-align: center;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: $br_r_5;
					overflow: hidden;
					img {
						height: 80px;
						width: 150px;
					}
					h4 {
						text-align: center;
					}
				}
			}
			&-col2 {
				width: $w_100;
				&-elements {
					gap: 1rem;
					img {
						width: 100%;
						height: 350px;
					}
					p {
						line-height: 1.3rem;
						text-align: justify;
					}
				}
			}
		}
	}
}

@media (max-width: $sm) {
	.inside {
		.link {
			width: 42%;
		}
		.page__title {
			width: 70%;
			left: 15%;
			top: 36vh;
			padding: 1% 0 2% 0;
			font-size: 1.8em;
			font-weight: 500;
			span {
				font-size: 0.8rem;
			}
		}
		.inside__elements {
			margin-top: 20%;
			padding: 0 0 5% 0;
			position: relative;
			&-col1 {
				height: 300px;
				h3 {
					top: -15%;
				}
				&-categories {
					border: 3px solid $light;
					width: 100%;
					height: 115px;
					padding: 3% 0;
					img {
						width: 130px;
					}
					h4 {
						line-height: 0.8rem;
					}
				}
			}
			&-col2 {
				&-elements {
					img {
						height: 300px;
					}
				}
			}
		}
	}
}

@media (max-width: $xs) {
	.inside {
		.page__title {
			width: 80%;
			left: 10%;
			top: 36vh;
			padding: 1% 0 2% 0;
			font-size: 1.5em;
			font-weight: 500;
			span {
				font-size: 0.7rem;
			}
		}
		.inside__elements {
			margin-top: 25%;
			padding: 0 0 7% 0;
			&-col1 {
				height: 290px;
				h3 {
					font-size: 1rem;
					text-transform: uppercase;
				}
				&-categories {
					height: 115px;
					padding: 3% 0;
					img {
						width: 120px;
						height: 70px;
					}
					h4 {
						line-height: 0.8rem;
					}
				}
			}
			&-col2 {
				&-elements {
					img {
						height: 290px;
					}
				}
			}
		}
	}
}
