@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 12399;
	.modalContainer {
		overflow: hidden;
		border-radius: $br_r_10;
		max-width: 700px;
		width: 95%;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		background: #25292b;
		box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.75);
		padding: 1% 2% 2% 2%;
	}
	.modalBg {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: -1;
	}
}
